import React from 'react';

const LoaderComp = () => {
  return (
    <div>
      {' '}
      <section className='loadingHere'>
        <div class='loader'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
    </div>
  );
};

export default LoaderComp;
