import React, { useEffect, useState } from 'react';
import { Form, Container, Table } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import PaginationBox from '../../components/PaginatonBox';

import { useSelector, useDispatch } from 'react-redux';
import {
  getProductsAsync,
  reset,
  toggleFeaturedAsync,
  toggleNewArrivalAsync,
  toggleToDisplayAsync,
} from '../../redux/productsSlice';
import { getCategoriesAsync } from '../../redux/categorySlice';
import Paginate from '../../components/Paginate';
import { useParams } from 'react-router-dom';
import LoaderComp from '../../components/LoaderComp';

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;

  const products = useSelector((state) => state.products.products);
  const pages = useSelector((state) => state.products.pages);
  let page = useSelector((state) => state.products.page);
  const categories = useSelector((state) => state.categories.category);
  const toggleSuccess = useSelector((state) => state.products.toggleSuccess);

  const [sortState, setSortState] = useState('');
  const [catFilter, setCatFilter] = useState('');

  const loading = useSelector((state) => state.products.loading);

  const location = useLocation();

  const toggle = (totoggle, id) => {
    if (totoggle.toString() === 'Featured') {
      dispatch(toggleFeaturedAsync(id));
    } else if (totoggle.toString() === 'New') {
      dispatch(toggleNewArrivalAsync(id));
    } else if (totoggle.toString() === 'Display') {
      dispatch(toggleToDisplayAsync(id));
    }
  };

  useEffect(() => {
    if (toggleSuccess) {
      dispatch(getProductsAsync({ state: sortState, pageNumber, catFilter }));
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSuccess]);

  useEffect(() => {
    dispatch(getProductsAsync({ state: sortState, catFilter, pageNumber }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, catFilter]);

  useEffect(() => {
    dispatch(getProductsAsync({ state: sortState, pageNumber, catFilter }));
  }, [dispatch, sortState]);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.updateSuccess === true) {
        dispatch(getProductsAsync({ pageNumber }));
        location.state.updateSuccess = false;
      }
    }
  }, [dispatch, location]);

  useEffect(() => {
    dispatch(getProductsAsync({ pageNumber, catFilter }));
    dispatch(getCategoriesAsync());
  }, [dispatch, pageNumber]);

  return (
    <>
      <Container>
        <section>
          <h1>Products</h1>
        </section>

        <section className='ProductsWrapper'>
          <section className='productFilter'>
            <Form.Select
              aria-label='Default select example'
              onChange={(e) => {
                setSortState(e.target.value);
              }}
            >
              <option>SortBy</option>
              <option value='lowToHigh'>stock low to high</option>
              <option value='highToLow'>stock high to low</option>
            </Form.Select>
            <Form.Select
              aria-label='Default select example'
              onChange={(e) => {
                setCatFilter(e.target.value);
              }}
            >
              <option value=''>All Categories</option>
              {categories &&
                categories.map((cat) => {
                  return (
                    <option key={cat._id} value={cat._id}>
                      {cat.name}
                    </option>
                  );
                })}
            </Form.Select>
          </section>
          <hr />
          {!loading ? (
            <section className='tableHolder'>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Product</th>
                    {/* <th>Reviews</th> */}
                    <th>Rate/Dis</th>
                    <th>Stock</th>
                    <th>New</th>
                    <th>Featured</th>
                    <th>Display</th>
                    <th>edit</th>
                  </tr>
                </thead>
                <tbody>
                  {products &&
                    products.map((product, i) => {
                      return (
                        <>
                          <tr>
                            <td>
                              <section className='productList'>
                                <figure className='productList-image'>
                                  <img
                                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${product.heroImage}`}
                                    alt=''
                                  />
                                </figure>
                                <div className='productList-name'>
                                  <div>
                                    <strong>{product.name}</strong>
                                  </div>
                                  <div>Category: {product.category.name}</div>
                                  <div>Sub-cat: {product.subCategory}</div>
                                </div>
                              </section>
                            </td>
                            {/* <td>
                              <Link
                                to={`/reviews/${product._id}`}
                                className='mt-3'
                              >
                                reviews({product.numReviews})
                              </Link>
                            </td> */}
                            <td>
                              <div>
                                <span>${product.price}</span>
                              </div>
                              <div>
                                <strong className='csu ms-3'>
                                  {product.discount}%
                                </strong>
                              </div>
                            </td>

                            <td>{product.stock}</td>

                            <td>
                              {' '}
                              <section className='p-2 flex-between '>
                                <Form>
                                  <Form.Check
                                    type='switch'
                                    id='custom-switch'
                                    onClick={() => {
                                      toggle('New', product._id);
                                    }}
                                    checked={product.newArrival ? true : false}
                                    style={{ fontSize: '1rem' }}
                                    readOnly
                                  />
                                </Form>
                              </section>
                            </td>
                            <td>
                              {' '}
                              <section className='p-2 flex-between '>
                                <Form>
                                  <Form.Check
                                    type='switch'
                                    id='custom-switch'
                                    onClick={() => {
                                      toggle('Featured', product._id);
                                    }}
                                    checked={product.isFeatured ? true : false}
                                    style={{ fontSize: '1rem' }}
                                    readOnly
                                  />
                                </Form>
                              </section>
                            </td>
                            <td>
                              {' '}
                              <section className='p-2 flex-between '>
                                <Form>
                                  <Form.Check
                                    type='switch'
                                    id='custom-switch'
                                    onClick={() => {
                                      toggle('Display', product._id);
                                    }}
                                    checked={product.toDisplay ? true : false}
                                    style={{ fontSize: '1.2rem' }}
                                    readOnly
                                  />
                                </Form>
                              </section>
                            </td>
                            <td>
                              {' '}
                              <section className='p-2 flex-between '>
                                <Link to={`/edit-product/${product._id}`}>
                                  <i
                                    className='fa fa-edit'
                                    style={{ fontSize: '1.2rem' }}
                                  ></i>
                                </Link>
                              </section>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </Table>

              {products && (
                <Paginate
                  pages={pages}
                  page={page}
                  isAdmin={true}
                  list='products'
                  navigate={navigate}
                />
              )}
            </section>
          ) : (
            <LoaderComp />
          )}
        </section>
      </Container>
    </>
  );
};

export default Products;
