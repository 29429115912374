import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Modal,
  CloseButton,
} from 'react-bootstrap';

import PaginationBox from '../../components/PaginatonBox';
import SelectComp from '../../components/SelectComp';
import Tooltip from 'react-simple-tooltip';

import { useSelector, useDispatch } from 'react-redux';
import {
  createSubCategoryAsync,
  getCategoriesAsync,
  createSubCategory2Async,
  updateSubCategoryAsync,
  createCategoryAsync,
  updateCategoryAsync,
  toggleRemovedStatusAsync,
  resetToggle,
  reset,
} from '../../redux/categorySlice';
import axios from 'axios';
import LoaderComp from '../../components/LoaderComp';

const Categories = () => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.category);
  const loading = useSelector((state) => state.categories.loading);
  const creatingSub = useSelector((state) => state.categories.creatingSub);
  const creatingSub2 = useSelector((state) => state.categories.creatingSsub);
  const updatingDone = useSelector((state) => state.categories.updatingDone);
  const catUpdateDone = useSelector((state) => state.categories.catUpdateDone);
  const toggleSuccess = useSelector((state) => state.categories.toggleSuccess);

  const [edit, setEdit] = useState(false);

  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [seoTitle, setSeoTitle] = useState('');
  const [seoDescription, setSeoDescription] = useState('');
  const [seoKeywords, setSeoKeywords] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState(false);

  const [emptySubField, setEmptySubField] = useState(false);
  const [subCat2FieldEmpty, setSubCat2FieldEmpty] = useState(false);

  const [subcategory, setSubcategory] = useState('sub');
  const [categoryId, setCategoryId] = useState('');
  const [subCategoryName, setSubcategoryName] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');
  const [subCategory2Name, setSubcategory2Name] = useState('');

  const [subCategoryList, setSubCateogryList] = useState([]);

  const [editingId, setEditingId] = useState('');

  const [editCategory, setEditCategory] = useState('');
  const [editSubcategoryList, setEditSubCategoryList] = useState([]);
  const [editSubcategoryName, setEditSubcategoryName] = useState('');
  const [editSubcategoryId, setEditSubcategoryId] = useState('');
  const [editSubcat2List, setEditSubcat2List] = useState([]);
  const [show, setShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [cateogryFieldsError, setCategoryFieldsError] = useState(false);

  useEffect(() => {
    dispatch(getCategoriesAsync());
  }, [dispatch]);

  useEffect(() => {
    if (creatingSub2) {
      dispatch(getCategoriesAsync());
      setSubcategory('sub');
      dispatch(reset());
    }
  }, [dispatch, creatingSub2]);
  useEffect(() => {
    if (creatingSub) {
      dispatch(getCategoriesAsync());
      dispatch(reset());
    }
  }, [dispatch, creatingSub]);

  useEffect(() => {
    if (categories.length) {
      setCategoryId(categories[0]._id);
      setSubCateogryList(categories[0].subCategory);
      if (categories[0].subCategory.length > 0) {
        setSubCategoryId(categories[0].subCategory[0]._id);
      }
    }
  }, [categories]);

  const createSubCategory = (e) => {
    const data = { id: categoryId, name: subCategoryName };
    dispatch(createSubCategoryAsync(data));
  };

  const createSubCategory2 = () => {
    const data = {
      id: categoryId,
      subid: subCategoryId,
      name: subCategory2Name,
    };
    dispatch(createSubCategory2Async(data));
    dispatch(getCategoriesAsync());
  };

  const checkFields = async () => {
    if (categoryName.trim() === '' || categoryImage.trim() === '') {
      setCategoryFieldsError(true);
      return true;
    } else {
      setCategoryFieldsError(false);
      return false;
    }
  };

  const createCategory = () => {
    const data = {
      name: categoryName,
      image: categoryImage,
      seoTitle,
      seoDescription,
      keywords: seoKeywords,
    };
    dispatch(createCategoryAsync(data));

    // dispatch(getCategoriesAsync());
  };

  const updateCategory = () => {
    const data = {
      id: editingId,
      name: categoryName,
      image: categoryImage,
      seoTitle,
      seoDescription,
      keywords: seoKeywords,
    };
    dispatch(updateCategoryAsync(data));
  };

  useEffect(() => {
    if (updatingDone) {
      dispatch(getCategoriesAsync());
      dispatch(reset());
    }
  }, [dispatch, updatingDone]);

  useEffect(() => {
    if (catUpdateDone) {
      dispatch(getCategoriesAsync());
      dispatch(reset());
    }
  }, [dispatch, catUpdateDone]);

  const toggle = (id) => {
    dispatch(toggleRemovedStatusAsync(id));
  };

  useEffect(() => {
    if (toggleSuccess) {
      dispatch(getCategoriesAsync());
      dispatch(resetToggle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSuccess]);

  const uploadCategoryImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload', formData, config);

      setCategoryImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  if (loading) return <LoaderComp />;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <Container>
        <section>
          <h1>Categories</h1>
        </section>

        <section className='ManCatWrapper'>
          <button
            className='bton bton--sm bton--primary'
            onClick={(e) => {
              e.preventDefault();
              setIsEditing(false);
              setEditingId('');
              setCategoryName('');
              setSeoTitle('');
              setSeoDescription('');
              setSeoKeywords('');
              setCategoryImage('');
              handleShow();
            }}
          >
            Create Main Category
          </button>

          <Row>
            {categories &&
              categories.map((category, i) => {
                return (
                  <Col key={i} md={2}>
                    <section className='MainCatCard'>
                      <figure className='MainCatCard-image'>
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PREFIX}${category.image}`}
                          alt=''
                        />
                        <figcaption>
                          <div className='MainCatCard-text'>
                            {category.name}
                          </div>
                          <hr />
                          <div className='flexBetween mt-3'>
                            <Form>
                              <Form.Check
                                type='switch'
                                id='custom-switch'
                                onClick={() => {
                                  toggle(category._id);
                                }}
                                checked={!category.removedStatus ? true : false}
                              />
                            </Form>

                            <div
                              className='cpntr'
                              onClick={(e) => {
                                e.preventDefault();
                                setIsEditing(true);
                                setEditingId(category._id);
                                setCategoryName(category.name);
                                setSeoTitle(category.seoTitle);
                                setSeoDescription(category.seoDescription);
                                setSeoKeywords(category.keywords);
                                setCategoryImage(category.image);
                                setShow(true);
                              }}
                            >
                              Edit <i className='fa fa-edit ms-2 '></i>
                            </div>
                          </div>
                        </figcaption>
                      </figure>
                    </section>
                  </Col>
                );
              })}
          </Row>
        </section>

        <section>
          <Row>
            <Col md={3}>
              {edit ? (
                <section className='CatWrapper'>
                  <section>
                    <section
                      className='flex-between'
                      // onClick={() => }
                    >
                      <h2>
                        {editCategory.name}

                        <div className='text-small'>
                          edit options for {editCategory.name}
                        </div>
                      </h2>{' '}
                      <CloseButton
                        onClick={(e) => {
                          e.preventDefault();
                          setEditCategory('');
                          setEditSubCategoryList([]);
                          setEditSubcategoryName('');
                          setEditSubcategoryId('');
                          setEditSubcat2List([]);
                          setEdit(false);
                        }}
                      />
                      {/* <i
                        className='fa  fa-close (alias) p-2 cpntr'
                        onClick={(e) => {
                          e.preventDefault();
                          setEditCategory('');
                          setEditSubCategoryList([]);
                          setEditSubcategoryName('');
                          setEditSubcategoryId('');
                          setEditSubcat2List([]);
                        }}
                      ></i> */}
                    </section>

                    <hr />
                    {/* <section>
                      <div className='mb-4'>
                        <strong>Category:</strong> {editCategory.name}
                        <div className='text-small'>
                          edit options for {editCategory.name}
                        </div>
                      </div>
                    </section> */}

                    <h2> Select Sub-Category</h2>

                    <div className='text-small'>
                      subcategory you want to rename or edit its items
                    </div>
                    <div>
                      <Form.Select
                        aria-label='Default select example'
                        onChange={(e) => {
                          const findsubcat = editCategory.subCategory.find(
                            (ex) => ex._id === e.target.value
                          );
                          setEditSubcategoryName(findsubcat.name);
                          setEditSubcategoryId(findsubcat._id);
                          findsubcat.subCategory2 !== undefined &&
                            setEditSubcat2List(findsubcat.subCategory2);
                        }}
                      >
                        {editSubcategoryList.map((ec) => {
                          return <option value={ec._id}>{ec.name}</option>;
                        })}
                      </Form.Select>
                    </div>
                    <Form.Group
                      className='mb-3 mt-4'
                      controlId='formBasicEmail'
                    >
                      <Form.Label>Rename Sub Category</Form.Label>
                      <Form.Control
                        type='text'
                        value={editSubcategoryName}
                        onChange={(e) => setEditSubcategoryName(e.target.value)}
                        placeholder='eg: Masks'
                      />
                    </Form.Group>
                    {editSubcat2List && editSubcat2List.length > 0 && (
                      <Form.Group
                        className='mb-3 mt-4'
                        controlId='formBasicEmail'
                      >
                        <h3>Edit items of {editSubcategoryName} </h3>
                        <div className='text-small'>
                          edit sub categories 2 of above category
                        </div>
                        <ul style={{ padding: 0 }}>
                          {editSubcat2List.map((esc, i) => {
                            return (
                              <li key={esc._id}>
                                <Row>
                                  <Col className='col-sm-8 text-small cpr'>
                                    {esc.name}
                                  </Col>
                                  <Col
                                    className='col-sm-3 cda mb-2 cpntr'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setEditSubcat2List(
                                        editSubcat2List.filter(
                                          (f) => f._id !== esc._id
                                        )
                                      );
                                    }}
                                  >
                                    Delete
                                  </Col>
                                </Row>
                              </li>
                            );
                          })}
                        </ul>
                      </Form.Group>
                    )}

                    <button
                      className='bton bton--primary bton--full'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                          updateSubCategoryAsync({
                            id: editCategory._id,
                            subId: editSubcategoryId,
                            data: {
                              name: editSubcategoryName,
                              subCategory2: editSubcat2List,
                            },
                          })
                        );
                      }}
                    >
                      Save and Update
                    </button>
                    {/* <button className='bton bton--primary bton--full bton--danger'>
                      {' '}
                      Discard
                    </button> */}
                  </section>
                </section>
              ) : (
                <section className='CatWrapper'>
                  <h2>Create Sub-Categories</h2>
                  <hr />
                  <h3 className='mb-2'> Select Main Category</h3>
                  <div className='text-small'>
                    choose under which you want to create sub categories
                  </div>
                  <div>
                    <Form.Select
                      aria-label='Default select example'
                      onChange={async (e) => {
                        e.preventDefault();
                        setCategoryId(e.target.value);
                        const findCategory = await categories.find(
                          (element) => element._id === e.target.value
                        );
                        setSubCateogryList(findCategory.subCategory);

                        setSubCategoryId(findCategory.subCategory[0]._id);
                      }}
                    >
                      {categories.map((category, i) => {
                        return (
                          <option key={i} value={category._id}>
                            {category.name}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>

                  <section className='categories'>
                    <section className='flex-between categories-tabs'>
                      <div
                        className={subcategory === 'sub' ? 'active' : 'passive'}
                        onClick={(e) => {
                          e.preventDefault();
                          setSubcategory('sub');
                        }}
                      >
                        Sub Category
                      </div>
                      <div
                        className={subcategory === 'sub' ? 'passive' : 'active'}
                        onClick={(e) => {
                          e.preventDefault();
                          setSubcategory('sub2');
                        }}
                      >
                        Sub Categoty 2
                      </div>
                    </section>

                    {subcategory === 'sub' ? (
                      <section>
                        <Form.Group className='mb-3' controlId='formBasicEmail'>
                          <Form.Label>New Sub Category Name</Form.Label>
                          <Form.Control
                            type='text'
                            onChange={(e) => setSubcategoryName(e.target.value)}
                            placeholder='eg: electronics'
                          />
                          {emptySubField && (
                            <small style={{ color: 'red' }}>
                              Sub category name is required
                            </small>
                          )}
                        </Form.Group>
                        <button
                          className='bton bton--primary bton--full me-4'
                          onClick={(e) => {
                            e.preventDefault();

                            if (subCategoryName.trim() === '') {
                              setEmptySubField(true);
                            } else {
                              createSubCategory();
                            }
                          }}
                        >
                          Create Sub Category
                        </button>
                        {/* <button className='bton bton--primary bton--full bton--danger'>
                          {' '}
                          Discard
                        </button> */}
                      </section>
                    ) : (
                      <section>
                        <h3 htmlFor='' className='mt-3'>
                          {' '}
                          Select Sub Category
                        </h3>
                        <div className='text-small'>
                          choose under which you want to create sub categorie 2
                        </div>
                        {subCategoryList.length === 0 ? (
                          <h1>Please add a sub category first</h1>
                        ) : (
                          <>
                            <Form.Select
                              aria-label='Default select example'
                              onChange={(e) => {
                                setSubCategoryId(e.target.value);
                              }}
                            >
                              {subCategoryList &&
                                subCategoryList.map((sc, i) => {
                                  return (
                                    <option key={i} value={sc._id}>
                                      {sc.name}
                                    </option>
                                  );
                                })}
                            </Form.Select>
                            <Form.Group
                              className='mb-3 mt-3'
                              controlId='formBasicEmail'
                            >
                              <Form.Label>New Sub Category 2 Name</Form.Label>
                              <Form.Control
                                type='text'
                                onChange={(e) =>
                                  setSubcategory2Name(e.target.value)
                                }
                                placeholder='eg: electronics'
                              />
                              {subCat2FieldEmpty && (
                                <small style={{ color: 'red' }}>
                                  Sub category 2 name is required
                                </small>
                              )}
                            </Form.Group>
                            <button
                              className='bton bton--primary bton--full'
                              onClick={(e) => {
                                e.preventDefault();
                                if (subCategory2Name.trim() === '') {
                                  setSubCat2FieldEmpty(true);
                                } else {
                                  createSubCategory2();
                                }
                              }}
                            >
                              Create Sub Category 2
                            </button>
                            {/* <button className='bton bton--primary bton--full bton--danger'>
                              {' '}
                              Discard
                            </button> */}
                          </>
                        )}
                      </section>
                    )}
                  </section>
                </section>
              )}
            </Col>
            <Col md={9}>
              <section className='CatWrapper'>
                {/* <section>
                  <SelectComp name='All Categories' />
                </section>
                <hr /> */}

                <section>
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>S.N</th>
                        <th>Main Category</th>
                        <th>Sub Categories</th>
                        {/* <th>Sub Categories 2</th> */}
                        {/* <th>Products</th> */}
                        <th>Manage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories &&
                        categories.map((category, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{category.name}</td>
                              <td>
                                {' '}
                                <ul>
                                  {category.subCategory.map((sc, j) => {
                                    var data = [];
                                    sc.subCategory2.map((e) => {
                                      return data.push(e.name + ', ');
                                    });
                                    return (
                                      <>
                                        <li key={j}>
                                          <Tooltip
                                            content={
                                              sc.subCategory2.length > 0 && data
                                            }
                                            style={{ 'white-space': 'nowrap' }}
                                          >
                                            {sc.name}({sc.subCategory2.length})
                                          </Tooltip>
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </td>

                              {category.subCategory.length === 0 ? (
                                <td>
                                  <Tooltip
                                    placement='left'
                                    content='No Sub-category to edit'
                                    style={{ 'white-space': 'nowrap' }}
                                  >
                                    Edit <i className=' fa fa-edit'></i>
                                  </Tooltip>
                                </td>
                              ) : (
                                <td
                                  className='cpntr'
                                  onClick={() => {
                                    setEdit(true);
                                    setEditCategory(category);
                                    setEditSubCategoryList(
                                      category.subCategory
                                    );
                                    category.subCategory.length > 0 &&
                                      setEditSubcategoryName(
                                        category.subCategory[0].name
                                      );
                                    category.subCategory.length > 0 &&
                                      setEditSubcategoryId(
                                        category.subCategory[0]._id
                                      );

                                    setEditSubcat2List(
                                      category.subCategory[0].subCategory2
                                    );
                                  }}
                                >
                                  Edit <i className=' fa fa-edit'></i>
                                </td>
                              )}
                            </tr>
                          );
                          // return category.subCategory.map((sc, j) => {
                          //   count++;
                          //   return (
                          //     <>
                          //       <tr key={j}>
                          //         <td>{count}</td>
                          //         <td>{category.name}</td>
                          //         <td>{sc.name}</td>
                          //         <td>
                          //           <ul>
                          //             {sc.subCategory2.map((ssc, k) => {
                          //               return (
                          //                 <>
                          //                   <li key={k}>{ssc.name}(12)</li>
                          //                 </>
                          //               );
                          //             })}
                          //           </ul>
                          //         </td>
                          //         <td>21</td>

                          //         <td onClick={() => setEdit(true)}>edit</td>
                          //       </tr>
                          //     </>
                          //   );
                          // });
                        })}
                    </tbody>
                  </Table>
                </section>
              </section>
            </Col>
          </Row>
        </section>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditing ? 'Edit' : 'Add'} Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type='text'
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
                {cateogryFieldsError && categoryName.trim() === '' && (
                  <small style={{ color: 'red' }}>
                    Category Name is required
                  </small>
                )}
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>SEO Title</Form.Label>
                <Form.Control
                  type='text'
                  value={seoTitle}
                  onChange={(e) => setSeoTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>SEO description</Form.Label>
                <Form.Control
                  type='text'
                  value={seoDescription}
                  onChange={(e) => setSeoDescription(e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>SEO Keyword(seprated with comma)</Form.Label>
                <Form.Control
                  type='text'
                  value={seoKeywords}
                  onChange={(e) => setSeoKeywords(e.target.value)}
                />
              </Form.Group>
              <Form.Group className='mb-3' controlId='formBasicPassword'>
                <Form.Label>Category Image</Form.Label>
                <Form.Control
                  type='file'
                  onChange={uploadCategoryImageHandler}
                />
                {cateogryFieldsError && categoryImage.trim() === '' && (
                  <small style={{ color: 'red' }}>Image is required</small>
                )}
              </Form.Group>

              {categoryImage !== '' && (
                <figure className='preview'>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PREFIX}${categoryImage}`}
                    alt=''
                  />
                </figure>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={async (e) => {
                e.preventDefault();
                const isValid = await checkFields();

                if (!isValid) {
                  if (isEditing) {
                    updateCategory();
                  } else {
                    createCategory();
                  }
                  handleClose();
                }
              }}
              className='bton bton--sm bton--primary'
            >
              {!isEditing ? 'Add' : 'Edit'}
            </button>
            <button
              className='bton bton--sm bton--ghost--danger'
              onClick={handleClose}
            >
              cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Categories;
