import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProductsAsync = createAsyncThunk(
  'products/getProductsAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.post(
      `/api/products/dashboard?pageNumber=${data.pageNumber}`,
      {
        sortState: data.state === undefined ? '' : data.state,
        catFilter:
          data.catFilter === undefined || data.catFilter === ''
            ? ''
            : data.catFilter,
      }
    );

    if (response.status === 200) {
      const products = response.data.products;
      const pages = response.data.pages;
      const page = response.data.page;
      const count = response.data.page;
      return { products, pages, page, count };
    }
  }
);

export const addProductAsync = createAsyncThunk(
  'products/addProductAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.post('/api/products/', data, config);
    if (response.status === 201) {
      const products = response.data.products;
      return { products };
    }
  }
);

export const getProductDetailsAsync = createAsyncThunk(
  'products/getProductsDetailsAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(`/api/products/${id}`, config);

    if (response.status === 200) {
      const product = response.data;
      return { product };
    }
  }
);

export const deleteProductAsync = createAsyncThunk(
  'products/deleteProductAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.delete(`/api/products/${id}`, config);

    if (response.status === 200) {
      const product = response.data;
      return { product };
    }
  }
);

export const getProductReviewAsync = createAsyncThunk(
  'products/getProductReviewAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.get(`/api/products/reviews/${id}`, config);

    if (response.status === 200) {
      const product = response.data;
      return { product };
    }
  }
);

export const updateProductAsync = createAsyncThunk(
  'products/updateProductAsync',
  async (form, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const { id, data } = form;
    const response = await axios.put(`/api/products/${id}`, data, config);
    if (response.status === 200) {
      const updatedProduct = response.data;
      return { updatedProduct };
    }
  }
);

export const toggleFeaturedAsync = createAsyncThunk(
  'products/toggleFeaturedAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/products/togglefeatured/${id}`,
      {},
      config
    );
    if (response.status === 200) {
      const togF = response.data;
      return { togF };
    }
  }
);

export const toggleToDisplayAsync = createAsyncThunk(
  'products/toggleToDisplayAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/products/toggletodisplay/${id}`,
      {},
      config
    );
    if (response.status === 200) {
      const toggle = response.data;
      return { toggle };
    }
  }
);

export const toggleNewArrivalAsync = createAsyncThunk(
  'products/toggleNewArrivalAsync',
  async (id, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/products/togglenewarrival/${id}`,
      {},
      config
    );
    if (response.status === 200) {
      const toggle = response.data;
      return { toggle };
    }
  }
);

export const togglePublishedAsync = createAsyncThunk(
  'products/togglePublishedAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.put(
      `/api/products/togglepublish/${data.id}/${data.reviewid}`,
      {},
      config
    );
    if (response.status === 200) {
      const toggle = response.data;
      return { toggle };
    }
  }
);

export const deleteReviewAsync = createAsyncThunk(
  'products/deleteReviewAsync',
  async (data, thunkApi) => {
    const { user } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    const response = await axios.delete(
      `/api/products/deletereview/${data.id}/${data.reviewid}`,
      config
    );
    if (response.status === 200) {
      return 'Review Deleted';
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    product: null,
    loading: false,
    pages: 1,
    page: 1,
    count: 0,
    detailsLoading: false,
    updateSuccess: false,
    toggleSuccess: false,
    reviews: [],
    deleteSuccess: false,
  },
  reducers: {
    reset: (state) => {
      state.toggleSuccess = false;
      state.updateSuccess = false;
      state.deleteSuccess = false;
    },

    sortLtoH: (state) => {
      state.products = state.products.sort((a, b) =>
        a.stock > b.stock ? 1 : -1
      );
    },

    sortHtoL: (state) => {
      state.products = state.products.sort((a, b) =>
        a.stock < b.stock ? 1 : -1
      );
    },
  },
  extraReducers: {
    [getProductsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getProductsAsync.fulfilled]: (state, action) => {
      state.products = action.payload.products;
      state.pages = action.payload.pages;
      state.page = action.payload.page;
      state.count = action.payload.count;
      state.loading = false;
    },

    [addProductAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addProductAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getProductDetailsAsync.pending]: (state, action) => {
      state.detailsLoading = true;
      state.productDetailsSuccess = false;
    },
    [getProductDetailsAsync.fulfilled]: (state, action) => {
      state.productDetailsSuccess = true;
      state.product = action.payload.product;
      state.detailsLoading = false;
    },
    [updateProductAsync.pending]: (state, action) => {
      state.updateSuccess = true;
    },
    [updateProductAsync.fulfilled]: (state, action) => {
      state.updateSuccess = false;
    },
    [toggleFeaturedAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [toggleNewArrivalAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [toggleToDisplayAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [getProductReviewAsync.pending]: (state, action) => {
      state.detailsLoading = true;
    },
    [getProductReviewAsync.fulfilled]: (state, action) => {
      state.reviews = action.payload.product;
      state.detailsLoading = false;
    },
    [togglePublishedAsync.fulfilled]: (state, action) => {
      state.toggleSuccess = true;
    },
    [deleteReviewAsync.fulfilled]: (state, action) => {
      state.deleteSuccess = true;
    },
    [deleteProductAsync.fulfilled]: (state, action) => {
      state.deleteSuccess = true;
    },
  },
});

export const { reset, sortLtoH, sortHtoL } = productsSlice.actions;

export default productsSlice.reducer;
