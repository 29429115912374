import React, { useState, useEffect } from 'react';
import { Container, Modal, Row, Form, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductImage from '../../assets/images/productimage.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCampaignsAsync,
  createCarouselsAsync,
  deleteCampaignsAsync,
  deleteCarouselsAsync,
  getCampaignAsync,
  getCarouselsAsync,
  reset,
  updateCampaignAsync,
} from '../../redux/carouselSilce';
import axios from 'axios';
import SearchProductsDropdown from '../../components/SearchProductsDropdown';
import LoaderComp from '../../components/LoaderComp';

const Slider = () => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [addSlider, setAddSlider] = useState(true);

  const carousel = useSelector((state) => state.carousels.carousel);
  const campaign = useSelector((state) => state.carousels.campaign);
  const updateSuccess = useSelector((state) => state.carousels.updateSuccess);
  const loading = useSelector((state) => state.carousels.loading);

  //CAROUSEL STATES
  const [carouselLink, setCarouselLink] = useState('');
  const [carouselImage, setCarouselImage] = useState('');
  const [products, setProducts] = useState([]);

  //CAMPAIGN STATES
  let [productList, setProductList] = useState([]);
  const [campaignTitle, setCampaignTitle] = useState('');
  const [campaignLink, setCampaignLink] = useState('');
  const [campaignImage, setCampaignImage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState('');

  const [validateCaru, setValidateCaru] = useState(false);
  const [validateCamp, setValidateCamp] = useState(false);

  const uploadCarouselImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload', formData, config);

      setCarouselImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  const uploadCampaignImageHandler = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const { data } = await axios.post('/api/upload', formData, config);

      setCampaignImage(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setAddSlider(true);
    setShow(true);
  };
  const handleShowAds = () => {
    setAddSlider(false);
    setShow(true);
  };

  useEffect(() => {
    if (updateSuccess) {
      dispatch(getCampaignAsync());
      dispatch(reset());
    }
  }, [dispatch, updateSuccess]);

  useEffect(() => {
    const fetch = async () => {
      await axios.get('/api/products/search').then((res) => {
        setProducts(res.data);
      });
    };

    fetch();
  }, []);

  useEffect(() => {
    dispatch(getCarouselsAsync());
    dispatch(getCampaignAsync());
  }, [dispatch]);

  const validateCraousel = async () => {
    if (carouselLink.trim() === '' || carouselImage.trim() === '') {
      return false;
    } else {
      return true;
    }
  };

  const validateCampaign = async () => {
    if (
      campaignTitle === '' ||
      campaignLink === '' ||
      campaignImage === '' ||
      productList.length <= 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const createCarousel = async () => {
    const validate = await validateCraousel();
    if (!validate) {
      setValidateCaru(true);
    } else {
      setValidateCaru(false);
      const data = {
        link: carouselLink,
        image: carouselImage,
      };
      dispatch(createCarouselsAsync(data));
      setCarouselLink('');
      setCarouselImage('');
      handleClose();
    }
  };

  const createCampaign = async () => {
    const validate = await validateCampaign();
    if (!validate) {
      setValidateCamp(true);
    } else {
      setValidateCamp(false);
      productList = productList.map((e) => e._id);
      const data = {
        title: campaignTitle,
        link: campaignLink,
        image: campaignImage,
        products: productList,
      };
      dispatch(createCampaignsAsync(data));
      setCampaignTitle('');
      setCampaignLink('');
      setCarouselImage('');
      setProductList([]);
      handleClose();
    }
  };

  const updateCampaign = async () => {
    const validate = await validateCampaign();

    if (!validate) {
      setValidateCamp(true);
    } else {
      setValidateCamp(false);
      productList = productList.map((e) => e._id);
      const data = {
        id: editingId,
        title: campaignTitle,
        link: campaignLink,
        image: campaignImage,
        products: productList,
      };
      dispatch(updateCampaignAsync(data));

      setCampaignTitle('');
      setCampaignLink('');
      setCarouselImage('');
      setProductList([]);
      handleClose();
    }
  };

  if (loading) return <LoaderComp />;

  return (
    <div>
      <Container>
        <section>
          <h1>Sliders</h1>
        </section>

        <section className='SliderWrapper'>
          <button className='bton bton--md bton--primary' onClick={handleShow}>
            Add Slider
          </button>

          <Row>
            {carousel.map((c, i) => {
              return (
                <Col md={4}>
                  <section className='sliderHolder'>
                    <figure className='sliderHolder-image'>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PREFIX}${c.image}`}
                        alt=''
                      />
                    </figure>
                    <section className='linkCarousel'>
                      <a href={c.link} target='_blank' rel='noreferrer'>
                        Link: {c.link}
                      </a>
                    </section>
                    <button
                      className='bton bton--danger'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteCarouselsAsync(c._id));
                      }}
                    >
                      delete
                    </button>
                    {/* <button
                      className='bton bton--primary '
                      onClick={handleShow}
                    >
                      Edit
                    </button> */}
                  </section>
                </Col>
              );
            })}
          </Row>
        </section>
        <section className='mt-5'>
          <h1>Ads and Campaign</h1>
        </section>

        <section className='SliderWrapper'>
          <button
            className='bton bton--md bton--primary'
            onClick={(e) => {
              e.preventDefault();
              setCampaignTitle('');
              setCampaignLink('');
              setCampaignImage('');
              setProductList([]);
              setEditingId('');
              setIsEditing(false);
              handleShowAds();
            }}
          >
            Add Campaign
          </button>

          <Row>
            {campaign.map((camp) => {
              return (
                <Col md={4}>
                  <section className='sliderHolder'>
                    <figure className='sliderHolder-image'>
                      <img
                        src={`${process.env.REACT_APP_IMAGE_PREFIX}${camp.image}`}
                        alt=''
                      />
                    </figure>
                    <section className='linkCarousel'>
                      Link:{' '}
                      <a href={camp.link} target='_blank' rel='noreferrer'>
                        {' '}
                        {camp.link}
                      </a>
                    </section>
                    <button
                      className='bton bton--danger'
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(deleteCampaignsAsync(camp._id));
                      }}
                    >
                      delete
                    </button>
                    <button
                      className='bton bton--primary ms-2'
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEditing(true);
                        setCampaignTitle(camp.title);
                        setCampaignLink(camp.link);
                        setCampaignImage(camp.image);
                        setProductList(camp.products);
                        setEditingId(camp._id);
                        handleShowAds();
                      }}
                    >
                      Edit
                    </button>
                    <ol className='mt-3'>
                      {camp.products.map((ep) => {
                        return <li>{ep.name}</li>;
                      })}
                    </ol>{' '}
                  </section>
                </Col>
              );
            })}
          </Row>
        </section>
      </Container>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          {addSlider ? (
            <Modal.Title>Add Slider </Modal.Title>
          ) : (
            <Modal.Title>{isEditing ? 'Edit' : 'Add'} Campaign </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {addSlider ? (
            <h1>
              {' '}
              <section>
                <Form>
                  <Row>
                    <Col md={12}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='paste link here'
                          value={carouselLink}
                          onChange={(e) => setCarouselLink(e.target.value)}
                        />
                        {validateCaru && carouselLink.trim() === '' && (
                          <small
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            Link is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <section className='galleryWrapper'>
                        <div className='center'>
                          <input
                            type='text'
                            placeholder='Please Select Image'
                            style={{ display: 'none' }}
                          />
                          <label>
                            <i className='fa fa-cloud-upload'></i>
                            <br />
                            <span>Click to upload Image</span>
                            <input
                              type='file'
                              style={{ display: 'none' }}
                              onChange={uploadCarouselImageHandler}
                            />
                          </label>
                        </div>
                        {validateCaru && carouselImage.trim() === '' && (
                          <small
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            Image is required
                          </small>
                        )}
                        {carouselImage && (
                          <figure className='preview'>
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PREFIX}${carouselImage}`}
                              alt=''
                            />
                          </figure>
                        )}
                      </section>
                    </Col>
                  </Row>
                </Form>
              </section>
            </h1>
          ) : (
            <>
              {' '}
              <section>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type='text'
                          value={campaignTitle}
                          onChange={(e) => setCampaignTitle(e.target.value)}
                          placeholder='Campaign title'
                        />
                        {validateCamp && campaignTitle.trim() === '' && (
                          <small
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            Title is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className='' controlId='formBasicEmail'>
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                          type='text'
                          value={campaignLink}
                          onChange={(e) => setCampaignLink(e.target.value)}
                          placeholder='paste link here'
                        />
                        {validateCamp && campaignLink.trim() === '' && (
                          <small
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            Link is required
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <section className='galleryWrapper'>
                        <div className='center'>
                          <input
                            type='text'
                            placeholder='Please Select Image'
                            style={{ display: 'none' }}
                          />
                          <label>
                            <i className='fa fa-cloud-upload'></i>
                            <br />
                            <span>Click to upload Image</span>
                            <input
                              type='file'
                              style={{ display: 'none' }}
                              onChange={uploadCampaignImageHandler}
                            />
                          </label>
                        </div>
                        {campaignImage && (
                          <figure className='preview'>
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PREFIX}${campaignImage}`}
                              alt=''
                            />
                          </figure>
                        )}
                        {validateCamp && campaignImage.trim() === '' && (
                          <small
                            style={{
                              color: 'red',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            Image is required
                          </small>
                        )}
                      </section>
                    </Col>
                    <Col md={8}>
                      <Form.Group className='mb-3' controlId='formBasicEmail'>
                        <Form.Label>Add products</Form.Label>
                        <SearchProductsDropdown
                          array={products}
                          placeholder={'Search Products'}
                          setProductList={setProductList}
                          productList={productList}
                        />
                      </Form.Group>
                    </Col>

                    <Col md='12'>
                      <ListGroup>
                        {productList.map((ele) => {
                          return (
                            <ListGroup.Item>
                              {' '}
                              <div className='flex-between'>
                                <span>{ele.name}</span>
                                <i
                                  className='fa fa-trash cda'
                                  onClick={(e) => {
                                    e.preventDefault();

                                    setProductList([
                                      ...productList.filter(
                                        (pl) => pl._id !== ele._id
                                      ),
                                    ]);
                                  }}
                                ></i>
                              </div>{' '}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                      <ol></ol>
                      {validateCamp && productList.length <= 0 && (
                        <small
                          style={{
                            color: 'red',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          At least one product is required
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form>
              </section>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className='bton bton--danger' onClick={handleClose}>
            Close
          </button>
          <button
            className='bton bton--primary'
            onClick={(e) => {
              e.preventDefault();
              if (addSlider) {
                createCarousel();
              } else {
                if (!isEditing) {
                  createCampaign();
                } else {
                  updateCampaign();
                }
              }
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Slider;
